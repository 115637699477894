<template>
  <header class=" w-full h-24 sm:h-36 flex items-center">
    <div class="w-full h-auto  flex items-center pl-3 sm:pl-12">
      <transition
        mode="out-in"
        enter-active-class="animate__animated animate__fadeInLeft animate__faster"
        leave-active-class="animate__animated animate__fadeOutLeft animate__faster"
      >
        <span
          @click="onBack"
          v-if="show"
          class="flex items-center mr-3 hover:bg-alphagray transition-all duration-100 cursor-pointer rounded-full p-1"
        >
          <MdArrowRoundBackIcon
            class="text-3xl text-black dark:fill-current text-white "
          />
          <ion-icon
            class="text-3xl text-black dark:text-white"
            name="arrow-back-outline"
          ></ion-icon>
        </span>
      </transition>
      <transition
        mode="out-in"
        enter-active-class="animate__animated animate__fadeInDown animate__faster"
        leave-active-class="animate__animated animate__fadeOutUp animate__faster"
      >
        <h1
          v-if="show"
          class="font-hslBold select-none text-black dark:text-white  text-4xl"
        >
          {{ title }}
        </h1>
      </transition>
    </div>
  </header>
</template>

<script>
import MdArrowRoundBackIcon from "vue-ionicons/dist/md-arrow-round-back.vue";
export default {
  data() {
    return {
      show: false,
    };
  },
  components: {
    MdArrowRoundBackIcon,
  },
  props: ["title"],
  mounted() {
    this.show = true;
  },
  methods: {
    onBack() {
      this.$router.go(-1);
      this.show = false;
      this.$emit("ContactShow");
    },
  },
};
</script>
